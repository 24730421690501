.faq-container {
  width: 90vw;
  max-width: 600px;
  margin: auto;
  padding: 20px 0;
}

.faq-category h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  padding: 0 20px;
  padding-top: 25px;
}

.accordion {
  border-radius: 6px;
  box-shadow: 0 2px 10px #0003;
  overflow: hidden;
  border: 1px solid #222222;

  * {
    font-size: 0.9rem !important;
  }
}

.accordion-item:not(:last-child) {
  border-bottom: 1px solid #545358;
}

.accordion-trigger {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  font-size: 1rem;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-transform: none;
  font-weight: 600;
  background-color: #121113;
}

.chevron {
  transition: transform 0.3s ease;
}

.chevron.open {
  transform: rotate(180deg);
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
  padding: 0 20px;
  background-color: #1a191b;
  color: #d8d5e0;
  text-transform: none;
}

.accordion-content.show {
  max-height: 200px;
  padding: 20px;
  border-top: 1px solid #545358;
}
