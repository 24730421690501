.hero-container {
  width: 90%;
  max-width: 1000px;
  background-size: cover;
  background-position: center;
  padding: 3rem;
  box-sizing: border-box;
  justify-content: space-between;
  aspect-ratio: 1.5;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-image: url("../assets/images/header-wide.png");
  gap: 3rem;

  @media (max-width: 767px) {
    background-image: url("../assets/images/header-long.png");
    height: 75vh;
    aspect-ratio: unset !important;
    padding: 1.5rem;
  }
}

.hero-container .songs {
  @media (max-width: 767px) {
    transform: scale(0.8);
    transform-origin: right bottom !important;
  }
}
